exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ForgotPassword_vd-captcha{margin-bottom:1.75rem;margin-top:1.75rem}.ForgotPassword_vd-captcha:empty{margin-top:0}.ForgotPassword_form{margin-top:3em}.ForgotPassword_no-bottom-margin{margin-bottom:0}.ForgotPassword_vd-mode button[disabled]{background-color:var(--theme-button_BackgroundColor,#1f7bc9);box-shadow:inset 0 var(--btn-boxShadowSize) 0 var(--theme-button_BottomBorderColor,#1962a1);color:var(--theme-button_Color,#fff)}.ForgotPassword_vd-mode button[disabled]:hover{box-shadow:inset 0 var(--btn-boxShadowSize) 0 var(--theme-button_Disabled_BottomBorderColor,#979797)}@media (min-width:env(--theme-breakpoints_Medium,960)px),print{.ForgotPassword_vd-captcha{margin-left:36%;text-align:left}}", ""]);

// exports
exports.locals = {
	"vd-captcha": "ForgotPassword_vd-captcha",
	"vdCaptcha": "ForgotPassword_vd-captcha",
	"form": "ForgotPassword_form",
	"no-bottom-margin": "ForgotPassword_no-bottom-margin",
	"noBottomMargin": "ForgotPassword_no-bottom-margin",
	"vd-mode": "ForgotPassword_vd-mode",
	"vdMode": "ForgotPassword_vd-mode"
};